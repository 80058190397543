import { graphql } from 'gatsby'
import React from 'react'

import { IndexQueryQuery } from '../../types/graphql-types'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import { Link } from 'gatsby'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const BlogIndex: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta

  return (
    <Layout edition="02-2021" location={location}>
      <Meta site={meta} />
      <div className="content-inner shadow-lg">
        <article className="row no-gutters text-center">
          <div className="col-12">
            <div
              className="bg-img h-100 w-100"
              style={{ backgroundImage: 'url(../../img/opening-e-store.jpg)' }}
            ></div>
          </div>
          <div className="p-5 col-12">
            <h2>Opening of a new e-store</h2>
            <Link
              to="/02-2021/opening-of-a-new-e-store/"
              className="btn btn-outline-primary stretched-link"
            >
              Read More
            </Link>
          </div>
        </article>
        <article className="row no-gutters">
          <div className="col-md-6 bg-primary text-white p-5">
            <h2>Straps sales on our corporate e-store</h2>
            <p className="small">
              Longines started to sell leather, NATO and rubber straps on its
              corporate e-store in France…
            </p>
            <Link
              to="/02-2021/straps-sales/"
              className="btn btn-outline-light stretched-link"
            >
              Read More
            </Link>
          </div>
          <div className="col-md-6 p-0">
            <div
              className="bg-img h-100 w-100"
              style={{
                backgroundImage: "url('../img/straps-sales.jpg'",
              }}
            ></div>
          </div>
        </article>
        <article className="row no-gutters">
          <div className="col-md-6 p-0 order-2 order-md-1">
            <div
              className="bg-img h-100 w-100"
              style={{
                backgroundImage: "url('../img/user-guide-page-header.jpg'",
              }}
            ></div>
          </div>
          <div className="col-md-6 bg-primary-900 text-white p-5 order-1 order-md-2">
            <h2>User Guide Page : Upgrade</h2>
            <p className="small">
              The user guide page has been upgraded to better serve our
              consumers helping them to find as fast as possible…
            </p>
            <Link
              to="/02-2021/user-guide-page/"
              className="btn btn-outline-light stretched-link"
            >
              Read More
            </Link>
          </div>
        </article>
        <article className="row no-gutters">
          <div className="col-md-6 bg-primary-800 text-white p-5">
            <h2>New "Our Collection" Page</h2>
            <p className="small">
              After the collection structure modification beginning of 2021...
            </p>
            <Link
              to="/02-2021/new-our-collection-page/"
              className="btn btn-outline-light stretched-link"
            >
              Read More
            </Link>
          </div>
          <div className="col-md-6">
            <div
              className="bg-img h-100 w-100"
              style={{
                backgroundImage: "url('../img/our-collection.jpg'",
              }}
            ></div>
          </div>
        </article>
        <article className="row no-gutters">
          <div className="col-md-6 order-2 order-md-1">
            <div
              className="bg-img h-100 w-100"
              style={{
                backgroundImage: "url('../img/wiki-website-header.jpg'",
              }}
            ></div>
          </div>
          <div className="col-md-6 bg-primary-700 text-white p-5 order-1 order-md-2">
            <h2>Wiki Website</h2>
            <p className="small">
              The new training platform called “wiki website” allow you to
              manage the Longines website independently...
            </p>
            <Link
              to="/02-2021/wiki-website/"
              className="btn btn-outline-light stretched-link"
            >
              Read More
            </Link>
          </div>
        </article>
        <article className="row no-gutters">
          <div className="col-md-6 bg-primary text-white p-5">
            <h2>Collector's corner</h2>
            <p className="small">
              Our Longines collectors around the world will be delighted with a
              brand new section on our website...
            </p>
            <Link
              to="/02-2021/collectors-corner/"
              className="btn btn-outline-light stretched-link"
            >
              Read More
            </Link>
          </div>
          <div className="col-md-6">
            <div
              className="bg-img h-100 w-100"
              style={{
                backgroundImage: "url('../img/collectors-corner.jpg'",
              }}
            ></div>
          </div>
        </article>
        <article className="row no-gutters">
          <div className="col-md-6 bg-primary-700 text-white p-5">
            <h2>User Experience Improvements</h2>
            <p className="small">
              Having an ecommerce website is good but we need to sell!
            </p>
            <Link
              to="/02-2021/user-experience-ux-improvements/"
              className="btn btn-outline-light stretched-link"
            >
              Read More
            </Link>
          </div>
          <div className="col-md-6 p-0 ">
            <div
              className="bg-img h-100 w-100"
              style={{
                backgroundImage: "url('../img/ux-improvements.jpg'",
              }}
            ></div>
          </div>
        </article>
        <article className="row no-gutters">
          <div className="col-md-6 p-0 order-2 order-md-1">
            <div
              className="bg-img h-100 w-100"
              style={{
                backgroundImage: "url('../img/new-languages-header.jpg'",
              }}
            ></div>
          </div>
          <div className="col-md-6 bg-primary-900 text-white p-5 order-1 order-md-2">
            <h2>Addition of new languages</h2>
            <p className="small">
              Two new languages will be available on the Longines website…
            </p>
            <Link
              to="/02-2021/addition-of-new-languages/"
              className="btn btn-outline-light stretched-link"
            >
              Read More
            </Link>
          </div>
        </article>
        <article className="row no-gutters">
          <div className="col-md-6 bg-primary-800 text-white p-5">
            <h2>Preorder</h2>
            <p className="small">
              We will introduce the Preorder function early October 2021
              starting with...
            </p>
            <Link
              to="/02-2021/preorder/"
              className="btn btn-outline-light stretched-link"
            >
              Read More
            </Link>
          </div>
          <div className="col-md-6">
            <div
              className="bg-img h-100 w-100"
              style={{
                backgroundImage: "url('../img/preorder-header.jpg'",
              }}
            ></div>
          </div>
        </article>
        <article className="row no-gutters">
          <div className="col-md-6 order-2 order-md-1">
            <div
              className="bg-img h-100 w-100"
              style={{
                backgroundImage: "url('../img/online-exclusive-product.jpg'",
              }}
            ></div>
          </div>
          <div className="col-md-6 bg-primary-700 text-white p-5 order-1 order-md-2">
            <h2>Online exclusive products</h2>
            <p className="small">
              On Aug 16th the first Online Exclusive Longines watches campaign
              will start on our E-Commerce platforms....
            </p>
            <Link
              to="/02-2021/online-exclusive-products/"
              className="btn btn-outline-light stretched-link"
            >
              Read More
            </Link>
          </div>
        </article>
        <article className="row no-gutters">
          <div className="col-md-6 bg-primary text-white p-5">
            <h2>New markets will open Click & Reserve</h2>
            <Link
              to="/02-2021/new-markets-open-click-and-reserve/"
              className="btn btn-outline-light stretched-link"
            >
              Read More
            </Link>
          </div>
          <div className="col-md-6">
            <div
              className="bg-img h-100 w-100"
              style={{
                backgroundImage: "url('../img/new-market-will-open.jpg'",
              }}
            ></div>
          </div>
        </article>
        <article className="row no-gutters">
          <div className="col-md-6 order-2 order-md-1">
            <div
              className="bg-img h-100 w-100"
              style={{
                backgroundImage:
                  "url('../img/global-site-performance-header.jpg'",
              }}
            ></div>
          </div>
          <div className="col-md-6 bg-primary-600 text-white p-5 order-1 order-md-2">
            <h2>Global site performance</h2>
            <Link
              to="/02-2021/global-site-performance/"
              className="btn btn-outline-light stretched-link"
            >
              Read More
            </Link>
          </div>
        </article>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogIndex_02_2021_main {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
